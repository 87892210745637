import React, { useState } from 'react'
import Img, { FluidObject } from 'gatsby-image'
import { Link } from 'gatsby'

import Anchor from '../Anchor'

import './style.sass'

interface Props {
  content: {
    id: string
    anchor: string
    carousel: {
      id: string
      fluid: FluidObject
    }[]
  }
}

const NextCaseStudy: React.SFC<Props> = ({ nextPage }) => {
  return (
    <section className="next-case-study no-snap">
      <Link to={nextPage}>
        <div className="inner">
          <h3>
            Check out the <span className="highlight-this">next</span>
            &nbsp;case&nbsp;study
          </h3>
        </div>
      </Link>
    </section>
  )
}

export default NextCaseStudy
