import React, { useState } from 'react'
import Img, { FluidObject } from 'gatsby-image'
import { Link } from 'gatsby'

import './style.sass'

interface Props {
  content: {
    id: string
    anchor: string
    carousel: {
      id: string
      fluid: FluidObject
    }[]
  }
}

const ArticleBlock: React.SFC<Props> = ({ content }) => {
  return (
    <article className="article-block no-snap">
      <div className="container">
        <div
          dangerouslySetInnerHTML={{
            __html: content.content.childMarkdownRemark.html,
          }}
        />
      </div>
    </article>
  )
}

export default ArticleBlock
