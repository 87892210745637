import React, { useState } from 'react'
import Img, { FluidObject } from 'gatsby-image'
import { Link } from 'gatsby'

import './style.sass'

interface Props {
  content: {
    id: string
    anchor: string
    carousel: {
      id: string
      fluid: FluidObject
    }[]
  }
}

const ContactBlock: React.SFC<Props> = ({ content }) => {
  return (
    <section className="textmodulelong theme-dark no-snap" id="contact">
      <div className="container">
        <div className="headline">
          <h2>Let's build a brand</h2>
          <h3>
            <span className="highlight-this">beyond the brew</span>
          </h3>
        </div>
        <div className="subline"></div>
        <div className="feature-points">
          <div className="point">
            <div
              style={{
                display: 'inline-flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <div className="left" style={{ marginRight: '20px' }}>
                <a href="tel:+61431535583">+61 431 535 583</a>
                <p>
                  <a href="mailto:dan@theroyals.com.au">dan@theroyals.com.au</a>
                </p>
              </div>

              <div className="right">
                <img
                  src="https://images.ctfassets.net/ic6xcdblapnb/58cvNxJikXXMnKKfNDG0si/738d945c795f34189a598d53213c40b8/Roylas-Y.png"
                  alt="Royals Y"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ContactBlock
