import React from 'react'

import Layout from '../layouts'
import Hero from '../components/Hero'
import ArticleBlock from '../components/ArticleBlock'
import ContactBlock from '../components/ContactBlock'
import VideoModal from '../components/VideoModal'
import NextCaseStudy from '../components/NextCaseStudy'
import FluidImageSplit from '../components/FluidImageSplit'

import './case.sass'

// Get all sections as ordered by PageLayout
// Currently only expects 1 PageLayout
// Pass all content from linked section into component
function getSection(sections) {
  if (!Array.isArray(sections)) {
    return null
  }

  return sections.map(section => {
    switch (section.__typename) {
      case 'ContentfulHero':
        return (
          <Hero
            key={section.id}
            useAnchor={false}
            homeBtn={true}
            content={section}
          />
        )
        break
      case 'ContentfulArticleBlock':
        return <ArticleBlock key={section.id} content={section} />
        break
      case 'ContentfulVideoModal':
        return <VideoModal key={section.id} content={section} />
        break
      case 'ContentfulFluidImageSplit':
        return <FluidImageSplit key={section.id} content={section} />
        break
      default:
        return null
    }
  })
}

const HomeTemplate: React.SFC<TypeFaceProps> = ({ pageContext }) => {
  return (
    <Layout bgColor="#DFECF9">
      {getSection(pageContext.sections)}
      {pageContext.nextPage && (
        <NextCaseStudy nextPage={pageContext.nextPage} />
      )}
      <ContactBlock />
    </Layout>
  )
}

export default HomeTemplate
