import React from 'react'
import Img from 'gatsby-image'

import './style.sass'

const FluidImageSplit = ({ content }) => {
  return (
    <div className="fluid-image-split">
      <div className="left">
        <Img
          className="background"
          style={{ position: 'absolute' }}
          fluid={content.images[0].fluid}
        />
      </div>

      {content.images[1] && (
        <div className="right">
          <Img
            className="background"
            style={{ position: 'absolute' }}
            fluid={content.images[1].fluid}
          />
        </div>
      )}
    </div>
  )
}

export default FluidImageSplit
