import React, { useState } from 'react'
import Img, { FluidObject } from 'gatsby-image'
import ModalVideo from 'react-modal-video'

import './style.sass'

import playButton from '../../assets/images/play-button.jpg'

const VideoModal = ({ content }) => {
  const [videoState, changeVideoState] = useState('CLOSED')

  const toggleModal = () => {
    return changeVideoState(videoState === 'CLOSED' ? 'OPEN' : 'CLOSED')
  }

  return (
    <div className="video-modal">
      <ModalVideo
        channel="vimeo"
        isOpen={videoState === 'OPEN'}
        videoId={content.videoId}
        onClose={toggleModal}
      />

      <div className="video-thumb" onClick={toggleModal}>
        <Img
          className="background"
          style={{ position: 'absolute' }}
          fluid={content.image.fluid}
        />
        <div className="overlay">
          <img src={playButton} alt="play" />
        </div>
      </div>
    </div>
  )
}

export default VideoModal
